.loading-text {
  font-size: 0.9rem;
  color:rgb(255, 255, 255);
}

.transition-text {
  opacity: 0;
  transition: opacity 2s linear 0.3s
}
.transition-text:focus {
  opacity: 1;
}

.fade-in-text {
  /* display: inline-block; */
  /* font-family: Arial, Helvetica, sans-serif; */
  /* font-size: 150px; */
  color: rgb(255, 255, 255);
  animation: fadeIn ease-in 4s;

}

/* Loader CSS: */

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 54px;
  height: 54px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
