body {
  background-color: #131313;
}
/* TYPO */
.text-display {
  color: aquamarine;
  text-decoration: none;
}
.text-link {
  color: yellowgreen;
  text-decoration: none;
}
.text-link:hover {
  color: aquamarine;
  text-decoration: none;
}
.text-online {
  color: #00cd26;
}

.text-note-this {
  color: #000000;
  background-color: rgb(255, 81, 0);
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
}

.text-user-info {
  color: #aeaeae;
  font-size: 1rem;
}
.text-animate {
  background: #f84016 linear-gradient(to right, #f85216, #0084ff, #f2c94c, #ffffff, #f88016) repeat;
  background-size: 500%;
  background-position: 0;
  /* -webkit-background-clip: text; */
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  animation: 10s linear infinite barprogress forwards;
}


/* BACKGROUNDS */
.bg-date-soon {
  background-color: #0099ff;
}
.bg-no-internet {
  background-color: #ff0000;
  width: 100%;
}
.bg-online {
  background-color: #00cd26;
  width: 100%;
}
.bg-pending {
  /* background-color: #ffd900; */
  /* border-radius: 10%; */
  border-color: rgb(228, 228, 228);
  border-style: solid;
  width: 100%;
}

.bg-old-unit {
  background-color: #654e02;
  width: 100%;
}

.bg-ready-unit {
  color: #131313;
  /* background-color: #ffd900; */
  width: 100%;
}

.play-icon {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}
